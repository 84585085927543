import { render, staticRenderFns } from "./AtomTeam.vue?vue&type=template&id=8c8de48e&scoped=true&"
import script from "./AtomTeam.vue?vue&type=script&lang=js&"
export * from "./AtomTeam.vue?vue&type=script&lang=js&"
import style0 from "./AtomTeam.vue?vue&type=style&index=0&id=8c8de48e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c8de48e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
